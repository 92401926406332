<template>
    <section class="lead-web-form">
        <div class="text-center mb-4 mt-5">
            <img :src="urlGenerator(logoUrl)" style="height:50px" alt="" class="img-fluid logo mt-3"/>
        </div>
        <form
            class="webform mt-3 --mx-8"
            ref="form"
            :data-url="formSubmitEndpoint"
            @submit.prevent="handleSubmit"
        >
            <h4 class="form-title my-5 text-center">{{ getWebFormTitle }}</h4>
            <div class="name-details mb-5">
                <!-- NAME -->
                <div class="row d-flex align-items-center mb-4">
                    <label class="col-12 col-sm-2 col-xl-3" for="name">{{
                            $t('name')
                        }}</label>
                    <app-input
                        :error-message="$errorMessage(errors, 'name')"
                        class="col-12 col-sm-10 col-xl-9"
                        id="name"
                        name="name"
                        :placeholder="$t('enter_name')"
                        type="text"
                        v-model="formData.name"
                    />
                </div>
            </div>

            <div class="contact-details">
                <h5 class="group-title">{{ $t('contact_info') }}</h5>

                <!-- PHONE -->
                <div class="row d-flex align-items-center my-4">
                    <label class="col-12 col-sm-2 col-xl-3" for="phone">{{
                            $t('phone')
                        }}</label>
                    <app-input
                        class="col-12 col-sm-6"
                        id="phone"
                        name="phone"
                        type="tel-input"
                        :placeholder="$t('enter_number')"
                        v-model="formData.phone[0].value"
                    />
                    <app-input
                        class="pt-3 pt-sm-0 col-6 col-sm-4 col-xl-3"
                        v-model="formData.phone[0].type_id"
                        type="search-and-select"
                        :options="paginateInputsOptions.phoneEmailTypeOptions"
                        :placeholder="$t('type')"
                    />
                </div>
                <!-- EMAIL -->
                <div class="row d-flex align-items-center my-4">
                    <label class="col-12 col-sm-2 col-xl-3" for="email">{{
                            $t('email')
                        }}</label>
                    <app-input
                        class="col-12 col-sm-6"
                        id="email"
                        name="email"
                        type="email"
                        :placeholder="$t('enter_email')"
                        v-model="formData.email[0].value"
                    />
                    <app-input
                        class="pt-3 pt-sm-0 col-6 col-sm-4 col-xl-3"
                        v-model="formData.email[0].type_id"
                        type="search-and-select"
                        :options="paginateInputsOptions.phoneEmailTypeOptions"
                        :placeholder="$t('type')"
                    />
                </div>
            </div>

            <h6 class="pb-3 pt-3" :key="toggleAddressDetails">
                <span class="cursor-pointer" @click="toggleAddressDetails = !toggleAddressDetails">
                    {{ $t("address_details") }}
                    <app-icon :name="toggleAddressDetails ? 'chevron-down'  :'chevron-right'"
                              class="size-14 text-primary" :key="toggleAddressDetails"/>
                </span>
            </h6>
            <template v-if="toggleAddressDetails">
                <div class="form-group">
                    <div class="row">
                        <div class="mb-0 col-sm-2 col-xl-3 d-flex align-items-center">
                            <label>{{ $t('country') }}</label>
                        </div>
                        <div class="col-sm-10 col-xl-9">
                            <app-input
                                type="search-select"
                                list-value-field="name"
                                :list="countryList"
                                :placeholder="$t('choose_a_country')"
                                v-model="formData.country_id"
                            />
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <div class="row">
                        <div class="mb-0 col-sm-2 col-xl-3 d-flex align-items-center">
                            <label>{{ $t('area') }}</label>
                        </div>
                        <div class="col-sm-10 col-xl-9">
                            <app-input
                                type="text"
                                :placeholder="$t('enter_area')"
                                v-model="formData.area"
                            />
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <div class="row">
                        <div class="mb-0 col-sm-2 col-xl-3 d-flex align-items-center">
                            <label>{{ $t('city') }}</label>
                        </div>
                        <div class="col-sm-10 col-xl-9">
                            <app-input
                                type="text"
                                :placeholder="$t('enter_city')"
                                v-model="formData.city"
                            />
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <div class="row">
                        <div class="mb-0 col-sm-2 col-xl-3 d-flex align-items-center">
                            <label>{{ $t('state') }}</label>
                        </div>
                        <div class="col-sm-10 col-xl-9">
                            <app-input
                                type="text"
                                :placeholder="$t('enter_state')"
                                v-model="formData.state"
                            />
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <div class="row">
                        <div class="mb-0 col-sm-2 col-xl-3 d-flex align-items-center">
                            <label>{{ $t('zip_code') }}</label>
                        </div>
                        <div class="col-sm-10 col-xl-9">
                            <app-input
                                type="text"
                                :placeholder="$t('enter_zip_code')"
                                v-model="formData.zip_code"
                            />
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="mb-0 col-sm-2 col-xl-3 d-flex align-items-center">
                        <label>{{ $t("address") }}</label>
                    </div>
                    <div class="col-sm-10 col-xl-9">
                        <app-input
                            type="textarea"
                            :placeholder="$t('add_address_details_here')"
                            v-model="formData.address"
                        />
                    </div>
                </div>
            </template>

            <div class="contact-details" v-if="customFields.length && customFieldDataLoaded">
                <h5 class="group-title">{{ $t('other_info') }}</h5>
                <div class="row d-flex align-items-center my-4" v-for="(field, fieldIndex) in customFields"
                     :key="'c_field_' + field.name + '_' + fieldIndex">
                    <label class="col-12 col-sm-2 col-xl-3" :for="field.name + '_' + fieldIndex">{{ field.name }}</label>
                    <template v-if="field.custom_field_type.name === 'text'">
                        <app-input
                            type="text"
                            class="col-12 col-sm-10 col-xl-9"
                            :id="field.name + '_' + fieldIndex"
                            v-model="customFieldValue[field.name]"
                        />
                    </template>
                    <template v-if="field.custom_field_type.name === 'textarea'">
                        <app-input
                            type="textarea"
                            class="col-12 col-sm-10 col-xl-9"
                            :id="field.name + '_' + fieldIndex"
                            v-model="customFieldValue[field.name]"
                        />
                    </template>
                    <template v-if="field.custom_field_type.name === 'radio'">
                        <app-input
                            type="radio"
                            class="col-12 col-sm-10 col-xl-9"
                            :radio-checkbox-name="field.name"
                            :list="generateInputList(field)"
                            v-model="customFieldValue[field.name]"
                        />
                    </template>
                    <template v-if="field.custom_field_type.name === 'checkbox'">
                        <app-input
                            type="checkbox"
                            class="col-12 col-sm-10 col-xl-9"
                            :radio-checkbox-name="field.name"
                            :list="generateInputList(field)"
                            v-model="customFieldValue[field.name]"
                        />
                    </template>
                    <template v-if="field.custom_field_type.name === 'select'">
                        <app-input
                            type="select"
                            class="col-12 col-sm-10 col-xl-9"
                            :list="generateInputList(field)"
                            v-model="customFieldValue[field.name]"
                        />
                    </template>
                    <template v-if="field.custom_field_type.name === 'number'">
                        <app-input
                            type="number"
                            class="col-12 col-sm-10 col-xl-9"
                            v-model="customFieldValue[field.name]"
                        />
                    </template>
                    <template v-if="field.custom_field_type.name === 'date'">
                        <app-input
                            type="date"
                            class="col-12 col-sm-10 col-xl-9"
                            v-model="customFieldValue[field.name]"
                        />
                    </template>
                </div>
            </div>
            <div class="row d-flex align-items-center my-4 no-gutters --ml-5">
                <app-pre-loader v-if="preloader"/>
                <button
                    v-else
                    type="submit"
                    class="btn btn-primary --ml-4 --offset-sm-2 offset-xl-0"
                    @click.prevent="submit"
                >
                    {{ $t('save') }}
                </button>

                <button
                    type="submit"
                    class="btn btn-secondary ml-2"
                    @click.prevent="clearForm"
                >
                    {{ $t('clear') }}
                </button>
            </div>
        </form>
        <div class="form-group">
            <div class="col-12">
                <p class="text-center mt-5 footer-copy">
                    {{ copyRightText }}
                </p>
                <br>
                <br>
            </div>
        </div>
    </section>
</template>
<script src="https://cdn.jsdelivr.net/npm/sweetalert2@10"></script>
<script>

import {FormSubmitMixin} from "../../../Mixins/Global/FormSubmitMixin";
import {companyName, urlGenerator} from "../../../Helpers/helpers";

export default {
    name: 'lead-web-form',
    mixins: [FormSubmitMixin],
    props: {
        configData: {
            type: Object,
        }
    },
    data() {
        return {
            toggleAddressDetails: false,
            preloader: false,
            errors: {},
            urlGenerator,
            formSubmitEndpoint: route('lead-web-form.store'),
            formHasErrors: false,
            countryList: [],
            paginateInputsOptions: {
                phoneEmailTypeOptions: {
                    url: route('phone_email_types.web-form'),
                    query_name: 'name',
                    per_page: 5,
                    modifire: (row) => ({id: row.id, value: this.$t(row.name)}),
                    loader: 'app-pre-loader',
                },
            },
            formData: {
                name: '',
                phone: [{value: '', type_id: ''}],
                email: [{value: '', type_id: ''}],
            },

            customFields: {},
            customFieldValue: {},
            customFieldDataLoaded: false
        };
    },
    created() {
        this.getCountries();
        this.getCustomFields();
    },
    methods: {
        getCustomFields() {
            this.axiosGet(route('lead.web-form-custom-fields')).then(({data}) => {
                this.customFields = data;
                this.customFields.forEach((el, i) => {
                    if (this.formData.custom_fields) {
                        //edit
                        let targetField = this.formData.custom_fields.find(
                            (e) => e.custom_field_id == el.id
                        );
                        if (el.custom_field_type.name == "checkbox") {
                            //checkbox

                            let options = [];
                            if (targetField) {
                                el.meta.split(",").map((e, i) => {
                                    if (targetField.value.split(",").find((el) => el == e))
                                        options.push(i);
                                });
                            }
                            this.customFieldValue[el.name] = options;
                            // console.log(options, "if if edit checkbox");
                        } else if (
                            el.custom_field_type.name == "select" ||
                            el.custom_field_type.name == "radio"
                        ) {
                            let v = undefined;
                            // select & radio
                            if (targetField) {
                                el.meta.split(",").map((e, i) => {
                                    if (targetField.value.split(",").find((el) => el == e)) v = i;
                                });
                            }
                            this.customFieldValue[el.name] = v;
                        } else if (el.custom_field_type.name == "date") {
                            //other

                            this.customFieldValue[el.name] = targetField
                                ? new Date(targetField.value)
                                : "";
                            // console.log("if else edit other");
                        } else {
                            //other

                            this.customFieldValue[el.name] = targetField
                                ? targetField.value
                                : "";
                            // console.log("if else edit other");
                        }
                    } else {
                        //add
                        if (
                            el.custom_field_type.name == "select" ||
                            el.custom_field_type.name == "radio"
                        ) {
                            // select & radio
                            this.customFieldValue[el.name] = undefined;
                        } else {
                            this.customFieldValue[el.name] =
                                el.custom_field_type.name == "checkbox" ? [] : "";
                        }
                        // console.log("else add");
                    }
                });
                setTimeout(() => {
                    this.dataLoaded = true;
                    this.customFieldDataLoaded = true;
                }, 300)
            });
        },
        generateInputList({meta}) {
            if (meta) {
                return meta.split(",").map((m, i) => {
                    return {id: i, value: m};
                });
            }
        },
        submit() {
            this.preloader = true;
            let customData = []
            this.customFields.map((el) => {
                let item = {
                    value:
                        el.custom_field_type.name == "checkbox"
                            ? el.meta.split(",").filter((e, i) => {
                                if (
                                    this.customFieldValue[el.name].includes(String(i)) ||
                                    this.customFieldValue[el.name].includes(i)
                                ) {
                                    return e;
                                }
                            })
                            : (el.custom_field_type.name == "select" ||
                                el.custom_field_type.name == "radio")
                                ? el.meta.split(",").find((e, i) => {
                                    return i == Number(this.customFieldValue[el.name]);
                                })
                                : this.customFieldValue[el.name],
                    custom_field_id: el.id,
                };
                customData.push(item);
            });

            this.formData.customs = customData;

            this.save(this.formData);
        },
        afterError(err) {
            this.errors = err.data.errors;
        },
        afterSuccess(response) {
            Swal.fire({
                title: this.$t('submitted'),
                text: response.data.message,
                icon: 'success',
                timer: 2000, // Auto-close after 3 seconds
                timerProgressBar: true, // Show progress bar
                showConfirmButton: false // Hide "OK" button
            });
            this.errors = {}
            this.clearForm();
        },
        afterFinalResponse() {
            this.preloader = false;
        },
        clearForm() {
            this.formData = {
                name: '',
                phone: [{value: '', type_id: ''}],
                email: [{value: '', type_id: ''}],
            };
            this.getCustomFields("person");
        },
        getCountries() {
            this.axiosGet(route('countries.web-form')).then(({data}) => {
                this.countryList = data;
            });
        }
    },
    computed:{
        logoUrl() {
            return this.configData.company_logo ?? "/images/logo.png";
        },
        getWebFormTitle() {
            return this.configData.web_from_title ?? this.$t('person_web_form');
        },
        copyRightText(){
            let date = new Date();
            return 'Copyright @ ' + date.getFullYear() + ' by ' + this.configData.company_name;
        }
    }
};
</script>

<style lang="scss">
.lead-web-form {
    width: 70%;
    margin: 0 auto;
}

// @media only screen and (min-width: 576px) {
//     $margin: 14rem;
//     .mx-8 {
//         margin-left: $margin !important;
//         margin-right: $margin !important;
//     }
// }

@media only screen and (min-width: 1200px) {
    .lead-web-form {
        width: 50%;
        margin: 0 auto;
    }
}
</style>
