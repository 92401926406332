<template>
    <app-modal
        modal-alignment="top"
        :modal-id="modalId"
        modal-size="default"
        @close-modal="closeModal"
    >
        <template slot="header">
            <h5 class="modal-title">{{ selectedUrl ? $t('edit') : $t('add') }}</h5>
            <button aria-label="Close" class="close outline-none" data-dismiss="modal" type="button">
        <span>
          <app-icon :name="'x'"></app-icon>
        </span>
            </button>
        </template>

        <template slot="body">
			<app-overlay-loader v-if="preloader"/>

			<form ref="form"
			      :data-url="selectedUrl ? selectedUrl : url"
			      class="mb-0"
			      :class="{'loading-opacity': preloader}">
				<div class="form-group">
					<label>{{ $t('name') }}</label>
					<app-input type="text"
					           v-model="formData.name"
					           :placeholder="$t('name')"
					           :error-message="$errorMessage(errors, 'name')"/>
				</div>

				<div class="form-group">
					<label>{{ $t('type') }}</label>
					<app-input type="select"
					           v-model="formData.type"
					           :list="paymentType"
					           @input="change"
					           :error-message="$errorMessage(errors, 'type')"
					/>
				</div>

				<div v-if="selectedUrl" class="form-group">
					<label>{{ $t('payment_method_status') }}</label>
					<app-input type="radio"
					           :radio-checkbox-name="'for-status'"
					           list-value-field="translated_name"
					           v-model="formData.status_id"
					           :list="statusList"/>
				</div>


				<div v-if="formData.type === 'stripe' || formData.type === 'paystack'" class="form-group">
					<label>{{ $t('public_key') }}</label>
					<app-input type="text"
					           :placeholder="$t('public_key')"
					           v-model="formData.public_key"
					           :error-message="$errorMessage(errors, 'public_key')"/>
				</div>

				<div v-if="formData.type === 'paypal'" class="form-group">
					<label>{{ $t('client_id') }}</label>
					<app-input type="text"
					           :placeholder="$t('client_id')"
					           v-model="formData.client_id"
					           :error-message="$errorMessage(errors, 'client_id')"/>
				</div>

				<div v-if="formData.type === 'razorpay'" class="form-group">
					<label>{{ $t('key_id') }}</label>
					<app-input type="text"
					           :placeholder="$t('key_id')"
					           v-model="formData.client_id"
					           :error-message="$errorMessage(errors, 'client_id')"/>
				</div>


				<div class="form-group" v-if="formData.type === 'paypal' || formData.type === 'razorpay' || formData.type === 'stripe' || formData.type === 'paystack'">
					<label>{{ $t('secret_key') }}</label>
					<app-input type="password"
					           :placeholder="$t('secret_key')"
					           v-model="formData.secret_key"
					           :error-message="$errorMessage(errors, 'secret_key')"/>
				</div>

				<div v-if="formData.type === 'paypal'" class="form-group">
					<label>{{ $t('mode') }}</label>
					<app-input type="radio"
					           :list="modeList"
					           v-model="formData.mode"
					           :error-message="$errorMessage(errors, 'mode')"/>
				</div>


			</form>

		</template>
        <template slot="footer">
            <button
                class="btn btn-secondary mr-2"
                data-dismiss="modal"
                type="button"
                @click.prevent="closeModal"
            >{{ $t('cancel') }}
            </button>
            <button class="btn btn-primary" type="button" @click.prevent="submit">
        <span class="w-100">
          <app-submit-button-loader v-if="loading"></app-submit-button-loader>
        </span>
                <template v-if="!loading">{{ $t('save') }}</template>
            </button>
        </template>
	</app-modal>
</template>

<script>
import {FormMixin} from "../../../../core/mixins/form/FormMixin";
import {PAYMENT_METHOD,GET_PAYMENT_METHOD_STATUS} from "../../../Config/ApiUrl";
import HelperMixin from "../../../Mixins/Global/HelperMixin";


export default {
	name: "PaymentMethodCreateEditModal",
	mixins: [FormMixin, HelperMixin],
	props: {
		modalId: {
			type: String,
		},
        tableId: {
            type: String,
        },
	},

	data() {
		return {
			url: PAYMENT_METHOD,
            loading : false,
			statusList: [],
			paymentType: [
				{id: '', value: 'Choose One', disabled: true},
				// {id: 'cash', value: this.$t('cash')},
				{id: 'stripe', value: this.$t('stripe')},
				{id: 'paypal', value: this.$t('paypal')},
				// {id: 'razorpay', value: this.$t('razorpay')},
				// {id: 'paystack', value: this.$t('paystack')}
			],
			modeList: [
				{id: 'production', value: this.$t('live')},
				{id: 'sandbox', value: this.$t('sandbox')}
			],
			formData: {
				is_default: false,
				public_key: '',
				secret_key: '',
				client_id: '',
				mode: '',
			},
			preloader: false,
			showError: false,
			errors: {}

		}
	},

	created() {
		this.getStatusList();
	},
	methods: {
		change() {
			this.formData.secret_key = '';
			this.formData.public_key = '';
			this.formData.client_id = '';
			this.formData.mode = '';
		},
		getStatusList() {
			this.axiosGet(GET_PAYMENT_METHOD_STATUS).then(response => {
				this.statusList = response.data
			});
		},
		beforeSubmit() {
			this.preloader = true;
		},
		submit() {
			this.save(this.formData);
		},
		afterFinalResponse() {
			this.preloader = false;
		},
		afterSuccess(response) {
            this.$toastr.s(response.data.message);
            this.$hub.$emit("reload-" + this.tableId);
            this.closeModal();
		},
		afterError(response) {
			this.message = '';
			this.loading = false;
			this.errors = response.data.errors || {};
			if (response.status != 422)
				this.$toastr.e(response.data.message || response.statusText)
		},
		afterSuccessFromGetEditData(response) {
			this.preloader = false;
			this.formData = response.data;
			this.formData.type = this.formData.alias;
			if (this.formData.type === 'stripe') {
				this.formData.public_key = response.data.client_key;
			} else if (this.formData.type === 'paypal') {
				this.formData.client_id = response.data.client_key;
			} else if (this.formData.type === 'razorpay') {
				this.formData.client_id = response.data.client_key;
			} else if (this.formData.type === 'paystack') {
                this.formData.public_key = response.data.client_key;
			}
		},
		hideModal() {
			$('#' + this.modalId).modal('hide');
		},
		closeModal() {
			this.hideModal();
			this.$emit('closeModal')
		}
	},
}
</script>
