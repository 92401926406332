<template>
    <div  class="d-flex align-items-center" style="gap: 10px">
        <div>
            <div v-if="activity" class="note note-warning p-3" style="width: 250px">{{ activity.title }}</div>

            <div class="p-1"  style="width: 250px" v-if="rowData.notes.length > 0">
                <app-icon :name="rowData.notes[0].icon"/>
                 <span>{{rowData.notes[0].note}}</span>
            </div>

        </div>

        <div v-if="activity">
            <div class="d-flex align-items-start">
                <div class="d-flex align-items-start">
                    <label v-if="!statusActive" @click="handleRadioClick" class="customized-radio mini radio-default mr-0" style="margin-top: 10px">
                        <input type="radio" class="radio-inline" value="true" v-model="statusActive" @click="handleRadioClick" />
                        <span class="outside"> <span class="inside"></span> </span>
                    </label>
                </div>

                <div>
                    <span v-if="activity.started_at">
                        <span v-if="activityIsToday(activity.started_at + ' ' + activity.start_time)">{{ $t("today") }} {{ activityTodayTime(activity.started_at + ' ' + activity.start_time) }}</span>
                        <span v-else>{{ formatDateTimeToLocal(activity.started_at + ' ' + activity.start_time) }}</span>
                    </span>
                    <span class="mx-2">|</span>
                    <span v-if="activity.ended_at">
                        <span v-if="activityIsToday(activity.ended_at + ' ' + activity.end_time)">{{ $t("today") }} {{ activityTodayTime(activity.ended_at + ' ' + activity.end_time) }}</span>
                        <span v-else>{{ formatDateTimeToLocal(activity.ended_at + ' ' + activity.end_time) }}</span>
                    </span>
                </div>
            </div>

            <div class="text-muted pt-1 d-flex align-items-center">
                <app-avatar
                    :title="activity.created_by.full_name"
                    avatar-class="avatars-w-20 mr-2"
                    alt-text="image"/>
                <p class="mb-0"> {{ activity.created_by.full_name }} </p>
            </div>
        </div>
    </div>
</template>

<script>
import CoreLibrary from "../../../../core/helpers/CoreLibrary";
import moment from "moment/moment";
import {
    formatDateTimeToLocal,
    formatDateToLocal,
    onlyTime,
    onlyTimeFromTime, textTruncate,
    time_format, urlGenerator
} from "../../../Helpers/helpers";
import AppWidgetWithIcon
    from "../../../../../../package/installer/src/resources/js/core/components/dashboard-widget/AppWidgetWithIcon.vue";

export default {
    name: "LatestActivity",
    components: {AppWidgetWithIcon},
    extends: CoreLibrary,
    props: {
        rowData: {
            type: Object,
            required: true
        },
        value: {
            type: Array,
        },
        tableId: {
            type: String
        }
    },
    data() {
        return {
            activity: this.value.length ? this.value[0] : null,
            formatDateTimeToLocal,
            formatDateToLocal,
            onlyTimeFromTime,
            onlyTime,
            textTruncate,
            urlGenerator,
            statusActive: false
        }
    },
    mounted(){
        this.statusActive =  +this.activity?.status_id === 12;
    },
    methods: {
        handleRadioClick() {
            this.activityChangeStatus(this.activity?.id);
        },
        activityIsToday(time) {
            let localTime = moment(time).utc(true)
                .local();
            return moment().diff(localTime, 'days') === 0
        },
        activityTodayTime(time) {
            return moment(time).utc(true)
                .local().format(`${time_format()}`)
        },
        activityChangeStatus(id) {
            //if (!Boolean(id)) return;
            this.axiosPost({ url: route('activities.done', {id: id}) })
                .then((response) => {
                    this.statusActive = true;
                    this.$toastr.s(response.data.message);
                })
                .catch((error) => console.log(error));
        },
    },
}
</script>

<style>
.activity-icon {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: var(--icon-hover-bg);
}
</style>
