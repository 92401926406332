<template>
    <div>
        <app-note :notes="note" :show-title="false" :content-type="'html'"/>
        <form
            class="webform mt-3 mx-8"
            ref="form"
            :data-url="formSubmitEndpoint"
            @submit.prevent="submit"
        >
            <div class="form-group mt-3">
                <div class="row">
                    <div class="mb-0 col-sm-3 d-flex align-items-center">
                        <label>{{ $t("embed_code") }}</label>
                    </div>
                    <div class="col-sm-9">
                        <app-input
                            type="textarea"
                            :placeholder="$t('embed_code')"
                            v-model="embedCode"
                            :disabled="true"
                        />
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="mb-0 col-sm-3 d-flex align-items-center">
                        <label>{{ $t("direct_link") }}</label>
                    </div>
                    <div class="col-sm-9">
                        <app-input
                            type="text"
                            :placeholder="$t('direct_link')"
                            v-model="directLink"
                            :disabled="true"
                        />
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="mb-0 col-sm-3 d-flex align-items-center">
                        <label>{{ $t("web_form_title") }}</label>
                    </div>
                    <div class="col-sm-9">
                        <app-input
                            type="text"
                            :placeholder="$t('web_form_title')"
                            v-model="formData.web_from_title"
                        />
                    </div>
                </div>
            </div>
            <div class="row d-flex align-items-center my-4 no-gutters">
                <button
                    type="submit"
                    class="btn btn-primary"
                    @click.prevent="submit"
                >
                    {{ $t('save') }}
                </button>
            </div>
        </form>
        <p class="lead mt-5">{{ $t('api_reference') }}</p>
        <app-note
            :notes="apiNote"
            :show-title="false"
            :content-type="'html'"
        />
    </div>
</template>
<script>

import {urlGenerator} from "../../../../Helpers/AxiosHelper";
import {FormSubmitMixin} from "../../../../Mixins/Global/FormSubmitMixin";

export default {
    name: "LeadWebFormSetting",
    mixins: [FormSubmitMixin],
    data() {
        return {
            directLink: urlGenerator('lead-web-form'),
            embedCode: `<iframe width="650" height="900" src="${urlGenerator('lead-web-form')}" frameborder="0" allowfullscreen></iframe>`,
            note: `${this.$t('lead_web_form_notification') + this.$t('or_you_can')} <a href="${urlGenerator('lead-web-form')}" target="_blank" class="btn-link">${this.$t('click_here')}</a>`,
            apiNote:[
                `${this.$t('endpoint')}: <code>${ route('lead-web-form.store') }</code> <br/>`,
                `${this.$t('available_methods')}: <b>POST</b> <br/>`,
                `${this.$t('example_payload')}:  <br/>`,
// The following contents of the pre tag has to be misindented this way,
`<pre style="color: #E91E63">{
    "name":"Robert Rosales",
    "phone": [
        {
            "value":"+1 702-675-3552",
            "type_id":2
        }
    ],
    "email":[
        {
            "value":"mysohisaw@mailinator.com",
            "type_id":2
        }
    ],
    "customs": []
}</pre>`
            ],
            formSubmitEndpoint :route('core.settings.index'),
            formData: {
                web_from_title: window.settings.web_from_title
            }
        }
    },
    methods: {
        submit() {
            const payload = {...window.settings, ...this.formData};
            delete payload.company_logo;
            this.save(payload);
        },
        afterError(err) {
            this.errors = err.response.data.errors;
        },
        afterSuccess(response) {
            this.$toastr.s(response.data.message);
            this.clearForm();
        }
    }
};
</script>
