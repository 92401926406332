<template>
    <div>
        <app-table
            id="all-payment-methods-table"
            showFilter
            :options="options"
            @action="triggerActions"
        />

        <payment-method-create-edit-modal
            v-if="isModalActive"
            :modal-id="'payment-method-modal'"
            :tableId="tableId"
            :selected-url="selectedUrl"
            @closeModal="closePaymentMethodModal"
        />

        <app-confirmation-modal
            v-if="confirmationModalActive"
            :firstButtonName="$t('yes')"
            modal-class="warning"
            modal-id="app-confirmation-modal"
            @confirmed="confirmed"
            @cancelled="cancelled"
        />

    </div>
</template>

<script>
import CoreLibrary from "../../../../core/helpers/CoreLibrary";
import {PAYMENT_METHOD} from "../../../Config/ApiUrl";

export default {
    name: "PaymentMethod",
    extends: CoreLibrary,
    props: {
        props: {
            default: ''
        },
        id: {
            type: String
        }
    },
    data() {
        return {
            selectedUrl: '',
            isModalActive: false,
            confirmationModalActive: false,
            status: '',
            paymentSetting: '',
            tableId: 'all-payment-methods-table',
            options: {
                url: PAYMENT_METHOD,
                showHeader: true,
                tableShadow: false,
                tablePaddingClass: 'p-0',
                columns: [
                    {
                        title: this.$t('name'),
                        type: 'text',
                        key: 'name',
                    },
                    {
                        title: this.$t('status'),
                        type: 'custom-html',
                        key: 'status',
                        modifier: (value) => {
                            return `<span class="badge badge-pill badge-${value.class}">${value.translated_name}</span>`;
                        }
                    },
                    {
                        title: this.$t('actions'),
                        type: 'action',
                        key: 'actions',
                    },
                ],
                paginationType: "pagination",
                responsive: true,
                rowLimit: 10,
                showAction: true,
                orderBy: 'desc',
                actionType: "default",
                actions: [
                    {
                        title: this.$t('edit'),
                        icon: 'edit',
                        type: 'edit',
                        component: 'tax-create-edit-modal',
                        modalId: 'tax-create-edit-modal',
                        modifier: () => this.$can('update_payment_method')

                    },
                    {
                        title: this.$t('delete'),
                        icon: 'trash-2',
                        type: 'modal',
                        component: 'app-confirmation-modal',
                        modalId: 'app-confirmation-modal',
                        url: PAYMENT_METHOD,
                        name: 'delete',
                        modifier: () => this.$can('delete_payment_method')

                    }
                ],
            },
        }
    },
    mounted() {
        this.$hub.$on('headerButtonClicked-' + this.id, (component) => {
            this.isModalActive = true;
            this.selectedUrl = '';
        })
    },
    methods: {
        openModal() {
            this.selectedUrl = '';
            this.isModalActive = true;
        },
        closePaymentMethodModal(){
            this.isModalActive = false;
            this.selectedUrl = '';
        },
        triggerActions(row, action, active) {
            this.selectedUrl = `${PAYMENT_METHOD}/${row.id}`;
            if (action.title === this.$t('edit')) {
                this.isModalActive = true;
            } else if (action.title === this.$t('delete')) {
                this.confirmationModalActive = true;
            }
        },
        confirmed() {
            this.axiosDelete(this.selectedUrl).then(response => {
                this.$toastr.s(response.data.message);
                this.$hub.$emit('reload-' + this.tableId);
            }).catch(({error}) => {
                this.$toastr.e(error.data.message);
            });
        },
        cancelled() {
            this.confirmationModalActive = false;
        }
    },
    beforeDestroy() {
        this.$hub.$off('headerButtonClicked-' + this.id);
    }
}
</script>
