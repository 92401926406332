<template>
    <div class="content-wrapper">
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <app-breadcrumb
                    :page-title="$t('payment')"
                    :directory="[$t('reports'), $t('payment')]"
                    :icon="'users'"
                />
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="text-sm-right mb-primary mb-sm-0 mb-md-0">
                    <div class="dropdown d-inline-block btn-dropdown">
                        <div class="dropdown-menu"></div>
                    </div>

                    <button
                        type="button"
                        class="btn btn-primary btn-with-shadow"
                        @click="exportReport"
                    >
                        {{ $t("export") }}
                    </button>
                </div>
            </div>
        </div>

        <app-table
            :id="tableId"
            :options="options"
            @getRows="afterBulkSelect"
        />
    </div>
</template>
<script>
import CoreLibrary from "@core/helpers/CoreLibrary.js";
import {mapGetters} from "vuex";


import {
    invoiceStatus,
    owner,
    person,
    contactType,
    paymentMethods
} from "@app/Mixins/Global/FilterMixins";

import {getCustomFileds} from "@app/Mixins/Global/CustomFieldMixin";
import {DeleteMixin} from "@app/Mixins/Global/DeleteMixin";
import LeadBulkActionMixin from "../../Contacts/BulkAction/LeadBulkActionMixin";
import {numberWithCurrencySymbol} from "../../../../Helpers/helpers";
import {axiosGet} from "../../../../Helpers/AxiosHelper";

export default {
    name: "ReportPayment",
    extends: CoreLibrary,
    mixins: [
        DeleteMixin,
        getCustomFileds,
        invoiceStatus,
        LeadBulkActionMixin,
        owner,
        person,
        contactType,
        paymentMethods
    ],
    data() {
        return {
            isInvoicePaymentModalActive: false,
            invoiceId: '',
            route,
            numberWithCurrencySymbol,
            invoiceSettings: {},
            isModalActive: false,
            isInvoiceStatusModalActive: false,
            selectedInvoiceUrl: '',
            isInvoiceModalActive: false,
            isContactModalActive: false,
            isSendModalActive: false,
            invoiceStatusTableId: 'invoice-status-table',
            tableId: "invoice-modal",
            contacttableId: "contact-modal",
            confirmationModalActive: false,
            organizationModal: false,
            personActivitiesModal: false,
            editedUrl: "",
            viewAllDeal: false,
            viewAllFollower: false,
            isNoteModal: false,
            noteRowData: null,
            personId: null,
            selectedUrl: "",
            activityData: {},
            followerUrl: "",
            bulkContext: 'invoice',
            updatedInvoice: null,
            invoiceRouteId: null,
            invoiceStatusId: null,
            commonColumn: [
                {
                    title: this.$t("invoice_number"),
                    type: "object",
                    key: "invoice_number",
                    modifier: (value, row) => {
                        return this.invoiceSettings ? this.invoiceSettings.invoice_prefix + value : value;
                    }
                },
                {
                    title: this.$t("deal_name"),
                    type: "object",
                    key: "deal",
                    modifier: (value, row) => {
                        return value.title;
                    }
                },
                {
                    title: this.$t("leads"),
                    type: "object",
                    key: "deal",
                    modifier: (value, row) => {
                        return value?.contact_person[0]?.name;
                    }
                },
                {
                    title: this.$t("payment_method"),
                    type: "custom-html",
                    key: "payment_method",
                    modifier: (value, row) => {
                        return value?.name ?
                            `<span class="badge badge-pill badge-${value?.alias === 'paypal' ? 'primary' : 'warning'}">${this.$t(value?.name)}</span>` : '';
                    },
                },
                {
                    title: this.$t("invoice_amount"),
                    type: "object",
                    key: "total",
                    modifier: (value, row) => {
                        return numberWithCurrencySymbol(value);
                    }
                },
                {
                    title: this.$t("owner"),
                    type: "custom-html",
                    key: "deal",
                    modifier: (value, row) => {
                        return value?.owner?.full_name ?? `<p class="m-0 font-size-90 text-secondary"> ${this.$t("user_deleted")}</p>`;
                    }
                }
            ],
            options: {
                name: this.$t("invoice_table"),
                url: route("payment.history"),
                showHeader: true,
                enableHighlights: false,
                enableSaveFilter: false,
                columns: [],
                filters: [
                    {
                        title: this.$t("created_date"),
                        type: "range-picker",
                        key: "date",
                        option: ["today", "thisMonth", "last7Days", "thisYear"],
                        permission: this.$can("view_invoice") ? true : false, //for all
                    },
                    {
                        title: this.$t("owner"),
                        type: "checkbox",
                        key: "owner_is",
                        option: [],
                        permission: window.user.roles.map(role => role.name).includes('App Admin')
                            || window.user.roles.map(role => role.name).includes('Manager'),
                    },
                    {
                        title: this.$t("deals"),
                        type: "search-and-select-filter",
                        key: "deals",
                        settings: {
                            url: route('selectable.deals'),
                            modifire: (v) => {
                                return { id: v.id, name: v.title }
                            },
                            per_page: 10,
                            queryName: "name",
                            loader: "app-pre-loader"
                        },
                        listValueField: "name"
                    },
                    {
                        title: this.$t("payment_method"),
                        type: "checkbox",
                        key: "payment_methods",
                        option: [],
                        permission: window.user.roles.map(role => role.name).includes('App Admin')
                            || window.user.roles.map(role => role.name).includes('Agent')
                            || window.user.roles.map(role => role.name).includes('Manager')
                    },
                ],
                showSearch: true,
                searchPlaceholder: 'Search invoice',
                showFilter: true,
                paginationType: "pagination",
                enableRowSelect: false,
                responsive: true,
                rowLimit: 10,
                showAction: true,
                orderBy: "desc",
                actionType: "dropdown",
                showCount: true,
                showClearFilter: true,
            },
        };
    },
    computed: {
        ...mapGetters({
            invoiceStatus: "getInvoiceStatus",
            paymentMethod : "getPaymentMethod"
        }),
    },
    methods:{
        exportReport(){
            window.location.replace(route('export.payments'))
        }

    },
    mounted() {
        this.getCustomFiled("invoice");
        this.$store.dispatch('getInvoiceStatus');
        this.invoiceSettings = window.settings;
    }
};

</script>
<style>
.person .link-list {
    white-space: nowrap !important;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
}
</style>
